










import { Vue, Component, Prop } from 'vue-property-decorator';
import { chunk } from 'lodash';

@Component
export default class ReservationNoti extends Vue {
  @Prop({
    required: true
  })
  public product!: any;

  public open(e) {
    const re = e.currentTarget;
    if (re.classList.contains('on')) {
      re.classList.remove('on');
    } else {
      re.classList.add('on');
    }
  }
  get notice() {
    return this.product.notice.filter((i) => i.body !== '');
    // return re.map((el) => {
    //   const list = Object.assign({}, el)
    //   list.isOpen = false;
    //   return list;
    // })
  }
}
